<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,200 L0,26 Q250,-16 500,26 L500,200 Q250,160 0,200Z" fill="#cee7f0"/>
				<path d="M0,30 L0,25 Q250,-15 500,25 L500,30 Q250,-10 0,30Z" fill="#00a7e5"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title-handwriting">Contact Us</p>
            </div>
		</div>
		
		<div id="content">
			<div class="form-container contact">
				<form id="contact-form" v-on:submit.prevent="contactSubmit" v-if="!sent">
					<p class="heading-2">To contact us, please fill out the form below.</p>
					<label for="name" :class="{ 'error': nameRequired && showError }">Name (required)</label>
					<input type="text" id="name" v-model="contactFormData.name">

					<label for="email" :class="{ 'error': emailRequired && showError }">Email (valid email required)</label>
					<input type="text" id="email" v-model="contactFormData.email">

					<label for="phone">Phone</label>
					<input type="text" id="phone" v-model="contactFormData.phone">

					<label for="country" :class="{ 'error': countryRequired && showError }">Country (required)</label>
					<input type="text" id="country" v-model="contactFormData.country">

					<label for="city" :class="{ 'error': cityRequired && showError }">City/Town (required)</label>
					<input type="text" id="city" v-model="contactFormData.city">

					<div>
						<label for="type" class="for-select">I am a</label>
						<select name="type" id="type" v-model="contactFormData.type">
							<option value="teacher">Teacher</option>
							<option value="parent">Parent/Other</option>
						</select>
					</div>

					<div>
						<label for="reason" class="for-select">I am enquiring today about</label>
						<select name="reason" id="reason" v-model="contactFormData.reason">
							<option :value="val" v-for="(val, i) in reasons" :key="i">{{ val }}</option>
						</select>
					</div>

					<div>
						<label for="materials" class="for-select">Do you currently use any Encore materials?</label>
						<select name="materials" id="materials" v-model="contactFormData.materials">
							<option :value="true">Yes</option>
							<option :value="false">No</option>
						</select>
					</div>

					<label for="findOutMessage">If no, then how did you find out about us?</label>
					<input type="text" id="findOutMessage" v-model="contactFormData.findOutMessage">

					<div v-if="contactFormData.type=='teacher'">
						<label for="subscribe" class="for-select">Would you like to subscribe to our online newsletter?</label>
						<select name="subscribe" id="subscribe" v-model="contactFormData.subscribeToVision6">
							<option :value="true">Yes</option>
							<option :value="false">No</option>
						</select>
					</div>

					<label for="message" :class="{ 'error': messageRequired && showError }">Message (required)</label>
					<textarea id="message" name="message" rows="4" v-model="contactFormData.message">

					</textarea>

					<p class="info" v-if="showError">Please make sure all required fields are filled out.</p>

					<div class="submit-container">
						<button class="button-round" type="submit" :disabled="submitPrevent">Send</button>
					</div>
				</form>

				<div v-else>
					<p class="info">Thank you for your inquiry. Our office will contact you soon.</p>
				</div>
			</div>

			<p class="heading">Australia / Head Office / Distribution Centre</p>
			
			<p class="info">Unit 2, No 39 Elgin Street, Alderley QLD 4051</p>
			<a class="info mail-link" href="mailto:info@accentpublishing.com.au">info@accentpublishing.com.au</a>

			<p class="info nomargin">1800 553 223 (Freecall Australia)</p>
			<p class="info">+61 7 3352 5092 (International)</p>

			<br>
			<br>

			<p class="heading" id="int-contacts">International Contacts</p>

			<div class="contacts-container">
				<div class="contact-item">
					<p class="heading-2">Hong Kong</p>
					<p class="info-bold">Eldon Yeung</p>
					<p class="info nomargin">Brio Music</p>
					<p class="info nomargin">eldon@briomusic.com</p>
					<p class="info">+852 6311 8111</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">New Zealand</p>
					<p class="info-bold">Mark Gibson</p>
					<p class="info nomargin">Encore Music Education Pty Ltd</p>
					<p class="info nomargin">mark@encoremusiceducation.com.au</p>
					<p class="info">+61 7 3352 5092</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">North America</p>
					<p class="info-bold">Mark Gibson</p>
					<p class="info nomargin">Encore Music Education Pty Ltd</p>
					<p class="info nomargin">mark@encoremusiceducation.com.au</p>
					<p class="info">+61 7 3352 5092</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">Malaysia</p>
					<p class="info-bold">Audrey Chin</p>
					<p class="info nomargin">Time and Tune</p>
					<p class="info nomargin">timeandtune@gmail.com</p>
					<p class="info">+60 12 828 5111</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">Indonesia</p>
					<p class="info-bold">Michelle Efferin</p>
					<p class="info nomargin">M.E Music Centre</p>
					<p class="info nomargin">m.e.musicschool@gmail.com</p>
					<p class="info">+62 813 2209 9937</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">Singapore</p>
					<p class="info-bold">Rita Liew</p>
					<p class="info nomargin">Music Matters</p>
					<p class="info nomargin">info@musicmatters.sg</p>
					<p class="info">+65 9635 2173</p>
				</div>
				<div class="contact-item">
					<p class="heading-2">Thailand</p>
					<p class="info-bold">Panadda Hongsakul</p>
					<p class="info nomargin">BMAS International Music and Performing Arts Academy</p>
					<p class="info nomargin">bmassukhumvit@gmail.com</p>
					<p class="info">+66 2258 1250</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { functions } from '@/firebase'
import ContactReasons from '@/contact.js';

export default {
	props: {
		reason: {
			type: String,
			default: ContactReasons.other
		},
		scroll: {
			type: Boolean,
			default: false
		},
		scrollInfo: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			contactFormData: {
				name: '',
				email: '',
				phone: '',
				country: '',
				city: '',
				type: 'teacher',
				materials: false,
				findOutMessage: '',
				subscribeToVision6: false,
				message: '',
				reason: this.reason
			},
			nameRequired: true,
			emailRequired: true,
			countryRequired: true,
			cityRequired: true,
			messageRequired: true,
			showError: false,
			sent: false,
			submitPrevent: false
		}
	},
	methods: {
		contactSubmit() {
			if (this.submitPrevent) {
				return;
			}

			this.submitPrevent = true;

			console.log('submit')

			if (!this.validateForm()) {
				this.submitPrevent = false;
				return;
			}

			if (this.contactFormData.type == 'parent') {
				this.contactFormData.subscribeToVision6 = false;
			}
			
			functions.httpsCallable('sendContactForm')({
				form: this.contactFormData
			}).then(() => {
				this.sent = true;
			});

		},
		validateForm() {
			const valid = !(this.nameRequired || this.emailRequired || this.countryRequired || this.cityRequired || this.messageRequired);
			this.showError = !valid;
			return valid;
		},
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		}
	},
	watch: {
		contactFormData: {
			deep: true,
			handler(val) {
				this.nameRequired = val.name === "";
				this.emailRequired = !this.validEmail(val.email);
				this.countryRequired = val.country === "";
				this.cityRequired = val.city === "";
				this.messageRequired = val.message === "";
			}
		}
	},
	computed: {
		reasons() {
			return ContactReasons
		}
	},
	mounted() {
		if (this.scroll) {
			this.$scrollTo('.form-container', { offset: -120 });
		} else if (this.scrollInfo) {
			this.$scrollTo('#int-contacts', { offset: -120 });
		}
	}
}
</script>